document.addEventListener('turbolinks:load', () => {
  // Retrieve attributes that have to be on a certain page for the js to be launched
  const sellFlatToggle = document.querySelector('.sell_flat_toggle');

  // If there is a "sell_flat_toggle" on the page, launch onClick simple_form input value attribution
  if (sellFlatToggle) {
    // make button toggles update hidden field
    // const toggleBtns = document.querySelector('.btn-group a');
    // toggleBtns.addEventListener('click', e => {
    //   e.preventDefault();
    //   var input = toggleBtns.siblings('.control-group').find('input[type=hidden]');
    //   if(input.length>0){
    //     if(input.val().toString() !== toggleBtns.data('value').toString()){
    //       input.val(toggleBtns.data('value')).trigger('change');
    //     }
    //   }
    // });

    // console.log("Hello world.");
    const sellHiddenCheckbox = document.querySelector('#sell_hidden_checkbox');
    // //const navBtns = Array.from(navigationManual.children);
    // console.log("VALUE: " + JSON.stringify(sellHiddenCheckbox));
    // //sellHiddenCheckbox.value = $(this).value();

    sellFlatToggle.addEventListener('click', e => {
      const targetDot = e.target.closest('input');

      if (!targetDot) return;

      let hasSiblings = false;
      if (sellHiddenCheckbox.previousElementSibling) {
        hasSiblings = true;
        const prevIsHidden = sellHiddenCheckbox.previousElementSibling.type == "hidden";
      }
      if (sellHiddenCheckbox.nextElementSibling){
        hasSiblings = true;
        const nextIsHidden = sellHiddenCheckbox.nextElementSibling.type == "hidden";
      }


      if (hasSiblings && prevIsHidden) {
        if(targetDot.checked) {
          sellHiddenCheckbox.previousElementSibling.value = 1;
          sellHiddenCheckbox.value = 1;
        } else {
          sellHiddenCheckbox.previousElementSibling.value = 0;
          sellHiddenCheckbox.value = 0;
        }
      } else if (hasSiblings && nextIsHidden) {
        if(targetDot.checked) {
          sellHiddenCheckbox.nextElementSibling.value = 1;
          sellHiddenCheckbox.value = 1;
        } else {
          sellHiddenCheckbox.nextElementSibling.value = 0;
          sellHiddenCheckbox.value = 0;
        }
      } else {
        if(targetDot.checked) {
          sellHiddenCheckbox.value = true;
        } else {
          sellHiddenCheckbox.value = false;
        }
      }
    });
  }
});
