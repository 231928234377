import { Controller } from "@hotwired/stimulus"

//connects to data-controller="map"
export default class extends Controller {
  static values = {
    apiKey: String,
    markers: Array
  }

  connect() {
    mapboxgl.accessToken = this.apiKeyValue

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/istvanlaz/ckezqjjnp0tyy19pfjpr6e6pv"
    })
    this.#addMarkersToMap()
    this.#fitMapToMarkers()
  }

  #addMarkersToMap() {
    this.markersValue.forEach((marker) => {
      const popup = new mapboxgl.Popup({
                                  closeButton: false,
                                  closeOnClick: false
                                })
                                .setHTML(marker.info_window);

      const localisationMarker = new mapboxgl.Marker()
                                        .setLngLat([marker.lng, marker.lat])
                                        .setPopup(popup)
                                        .addTo(this.map);

      const markerDiv = localisationMarker.getElement();

      markerDiv.addEventListener('mouseenter', () => {
        localisationMarker.togglePopup(popup);
      });

      markerDiv.addEventListener('mouseleave', () => {
        localisationMarker.togglePopup(popup);
      });

      markerDiv.addEventListener('click', () => {
        const url = "https://www.google.com/maps/place/De+Marselaerestraat+10,+1820+Steenokkerzeel/@50.9345005,4.4911325,17.57z/data=!4m6!3m5!1s0x47c3e766597bcea9:0xe74e4ee2e25d330a!8m2!3d50.9341346!4d4.493518!16s%2Fg%2F11hbqdfcf6";
        popup.remove();
        window.open(url, '_blank').focus();
      });
    })
  }

  #fitMapToMarkers() {
    const bounds = new mapboxgl.LngLatBounds()
    this.markersValue.forEach(marker => bounds.extend([ marker.lng, marker.lat ]))
    this.map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
  }
}
