document.addEventListener('turbolinks:load', () => {

  // Retrieve attributes that have to be on a certain page for the js to be launched
  const imageSlider = document.querySelector('.image_slider');
  //const navigationManual = document.querySelector('.navigation-manual');

  // If there is an image slider on the page, launch manual counter attribution
  if (imageSlider) {
    const navigationManual = document.querySelector('.navigation-manual');
    //const navBtns = Array.from(navigationManual.children);

    const carouselNavEmptySpace = document.querySelector('.carousel_nav_empty_space');
    const arrowLeft = document.querySelector('.fa-chevron-left');
    const arrowRight = document.querySelector('.fa-chevron-right');

    const frontChildLinks = document.querySelector('.front_child_links_ul');
    const itemBtns = Array.from(frontChildLinks.children);
    const childrenItemBtns = document.querySelectorAll('.front_child_list_item_bg_light');

    let toggleImageSlider = document.querySelector('.image_slider_toggle_screen');
    const carouselNavArrows = document.querySelector('.carousel_nav_arrows');
    const getAllSlide = document.querySelectorAll('.slide');
    const fullScreenCloseBtn = document.querySelector('.toggle_screen_close_btn');

    //verify if device used is small screen
    const isMobile = window.screen.width <= 600;

    //create a counter
    var counter = 0;
    let updatedCounter = 0;
    let counterKey;
    let nextCounterKey;
    let manualKey;


    //KEYBORD: create a function that will reattribute the counter if the user clicks right or left keyboard arrows
    window.addEventListener('keydown', e => {
      counterKey = document.getElementById('radio' + counter);
      manualKey = document.getElementById('man_rad' + counter);
      updatedCounter = counter;
      if (e.key === 'ArrowRight') {
        if (counterKey) {
          updatedCounter++;
          nextCounterKey = document.getElementById('radio' + updatedCounter);
          counterKey.checked = false;
          if (nextCounterKey) {
            nextCounterKey.checked = true;
          } else {
            nextCounterKey = document.getElementById('radio0');
            nextCounterKey.checked = true;
            updatedCounter = 0;
          }
        } else {
          updatedCounter = 0;
        }
      }

      if (e.key === 'ArrowLeft') {
        if (updatedCounter != 0) {
          updatedCounter--;
          nextCounterKey = document.getElementById('radio' + updatedCounter);
          counterKey.checked = false;
          nextCounterKey.checked = true;
        }
      }

      //remove all highlight on itemBtns
      childrenItemBtns.forEach((child) => {
        child.classList.remove('front_child_list_item_active');
      });
      counter = updatedCounter;
    });


    //DOTS: create a function that will reattribute the counter if the user clicks on the dots
    navigationManual.addEventListener('click', e => {
      const targetDot = e.target.closest('label');

      if (!targetDot) return;

      const targetDotArr = targetDot.id.split('');

      let targetId;
      let targetArr = [];

      if (targetDotArr[9]) {
        targetArr.push(targetDotArr[7]);
        targetArr.push(targetDotArr[8]);
        targetArr.push(targetDotArr[9]);
        targetId = targetArr.join('');
      } else if (targetDotArr[8]) {
        targetArr.push(targetDotArr[7]);
        targetArr.push(targetDotArr[8]);
        targetId = targetArr.join('');
      } else {
        targetId = targetDotArr[7];
      }

      let numericalTransformation = parseInt(targetId);

      //remove all highlight on itemBtns
      childrenItemBtns.forEach((child) => {
        child.classList.remove('front_child_list_item_active');
      });
      counter = numericalTransformation;
    });


    //FULL SCREEN: function that will activate full screen upon image click
    let fullScreenActive = false;
    carouselNavEmptySpace.addEventListener('click', e => {
      if (fullScreenActive === false) {
        toggleImageSlider.classList.toggle('toggle_screen_active');
        carouselNavArrows.classList.toggle('toggle_nav_active');
        if (isMobile) {
          carouselNavArrows.classList.toggle('full_screen_car_nav_arrows');
        }


        getAllSlide.forEach((slide, index) => {
          slide.classList.toggle('toggle_slide_active');
        });
        fullScreenActive = true;
      }
    });


    //CLOSE BTN: function that will de-activate full screen upon "screenClose" click
    fullScreenCloseBtn.addEventListener('click', e => {
      if (fullScreenActive === true) {
        toggleImageSlider.classList.toggle('toggle_screen_active');
        carouselNavArrows.classList.toggle('toggle_nav_active');
        if (isMobile) {
          carouselNavArrows.classList.toggle('full_screen_car_nav_arrows');
        }

        getAllSlide.forEach((slide, index) => {
          slide.classList.toggle('toggle_slide_active');
        });

        //remove all highlight on itemBtns
        childrenItemBtns.forEach((child) => {
          child.classList.remove('front_child_list_item_active');
        });
        fullScreenActive = false;
      }
    });


    //LEFT ARROW: create a function that will reattribute the counter if the user clicks on the left arrows
    arrowLeft.addEventListener('click', e => {
      counterKey = document.getElementById('radio' + counter);
      manualKey = document.getElementById('man_rad' + counter);
      updatedCounter = counter;

      if (updatedCounter != 0) {
        updatedCounter--;
        nextCounterKey = document.getElementById('radio' + updatedCounter);
        counterKey.checked = false;
        nextCounterKey.checked = true;
      }

      //remove all highlight on itemBtns
      childrenItemBtns.forEach((child) => {
        child.classList.remove('front_child_list_item_active');
      });
      counter = updatedCounter;
    });


    //RIGHT ARROW: create a function that will reattribute the counter if the user clicks on the right arrows
    arrowRight.addEventListener('click', e => {
      counterKey = document.getElementById('radio' + counter);
      manualKey = document.getElementById('man_rad' + counter);
      updatedCounter = counter;

      if (counterKey) {
        updatedCounter++;
        nextCounterKey = document.getElementById('radio' + updatedCounter);
        counterKey.checked = false;
        if (nextCounterKey) {
            nextCounterKey.checked = true;
        } else {
          nextCounterKey = document.getElementById('radio0');
          nextCounterKey.checked = true;
          updatedCounter = 0;
        }
      } else {
        updatedCounter = 0;
      }

      //remove all highlight on itemBtns
      childrenItemBtns.forEach((child) => {
        child.classList.remove('front_child_list_item_active');
      });
      counter = updatedCounter;
    });


    //ROOM IMAGES: create a function that will reattribute the counter if the user clicks on the list item images below the slider
    frontChildLinks.addEventListener('click', e => {
      //toggle fullScreenActive
      if (fullScreenActive === false) {
        toggleImageSlider.classList.toggle('toggle_screen_active');
        carouselNavArrows.classList.toggle('toggle_nav_active');
        if (isMobile) {
          carouselNavArrows.classList.toggle('full_screen_car_nav_arrows');
        }

        getAllSlide.forEach((slide, index) => {
          slide.classList.toggle('toggle_slide_active');
        });
        fullScreenActive = true;
      }

      const targetListItem = e.target.closest('li');

      if (!targetListItem) return;

      //remove all highlight on itemBtns
      childrenItemBtns.forEach((child) => {
        child.classList.remove('front_child_list_item_active');
      });

      //toggle highlight for targetChild in itemBtns
      const targetChild = targetListItem.querySelector('.front_child_list_item_bg_light');
      targetChild.classList.toggle('front_child_list_item_active');

      const targetListItemArr = targetListItem.id.split('');

      let targetListId;
      let targetListArr = [];

      //create an ordered array based on flatOrder-roomOrder-pictureOrder
      if (targetListItemArr[11]) {
        targetListArr.push(targetListItemArr[9]);
        targetListArr.push(targetListItemArr[10]);
        targetListArr.push(targetListItemArr[11]);
        targetListId = targetListArr.join('');
      } else if (targetListItemArr[10]) {
        targetListArr.push(targetListItemArr[9]);
        targetListArr.push(targetListItemArr[10]);
        targetListId = targetListArr.join('');
      } else {
        targetListId = targetListItemArr[9];
      }

      let numericTransformation = parseInt(targetListId);

      counter = numericTransformation;

      counterKey = document.getElementById('radio' + counter);
      manualKey = document.getElementById('man_rad' + counter);

      if (counterKey) {
        counterKey.checked = true;
      }
    });
  }
});
